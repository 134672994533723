<script>
	import RouteCards from '~/components/widgets/Home/CityfloRouteCards.svelte';
	import { isDoubleShiftRoute } from '~/utils/utils';

	export let routesData;

	let cities = [
		{
			name: 'MUMBAI',
			id: 1,
		},
		{
			name: 'HYDERABAD',
			id: 2,
		},
	];

	let routeMapPageLink;

	let activeCityId = 1;
	let routeData;
	function handleActiveCity(id) {
		activeCityId = id;
	}

	$: routeMapPageLink = `/routes/${activeCityId === 1 ? 'mumbai' : 'hyderabad'}/`;

	$: routeData = routesData[activeCityId].filter(isDoubleShiftRoute);
</script>

<div class="flex flex-col self-center md:hidden bg-neutral-0">
	<h2 class="heading text-start px-6 pt-16 mb-10">Our Routes</h2>
	<div class="mb-6 city-selection grid grid-cols-2 text-sm font-medium text-center relative overflow-hidden mx-8">
		{#each cities as city (city.id)}
			<button
				on:click={() => handleActiveCity(city.id)}
				class={`flex-1 cursor-pointer border-b-2 ease-linear pb-4 text-lg click px-6 ${
					activeCityId === city.id
						? 'text-primary font-extrabold border-accent'
						: 'text-primary-800 font-medium border-[#E6E9EC]'
				}`}
			>
				{city.name}
			</button>
		{/each}
	</div>
	<RouteCards routes={routeData} />
	<a
		id={`available-routes-${activeCityId === 1 ? 'mumbai' : 'hyderabad'}-find-your-bus`}
		class="btn btn-primary-hollow sm:max-w-[75%] mx-8 mb-12 nav-in shadow-none h-[3.25rem]"
		href={routeMapPageLink}>View all available routes</a
	>
</div>
